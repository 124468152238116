// src/components/ChurchRequests.js
import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomCarousel from './CustomCarousel'; // Importar el nuevo componente
import RichTextRenderer from './RichTextRenderer'; // Importar el componente RichTextRenderer
import './ChurchRequests.css';
import { LOCALE_ES, LOCALE_ZH } from '../i18n';
import { fetchChurchRequests, fetchPrayerRequests } from '../axiosInstance';

const ChurchRequests = () => {
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language ? i18n.language : LOCALE_ES;

  const [churchRequests, setChurchRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchChurchRequestsData = async () => {
    try {
      let fetchedData = await fetchChurchRequests(currentLang);
      if (!fetchedData && currentLang === LOCALE_ES) {
        fetchedData = await fetchChurchRequests(LOCALE_ZH);
      }
      if (fetchedData) {
        setChurchRequests(fetchedData);
      } else {
        console.error('Error fetching church requests');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching church requests:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChurchRequestsData();
  }, []);

  return loading ? (
    <Spin size="large" />
  ) : (
    <CustomCarousel
      title={t('church_requests')} // Título del carrusel
      items={churchRequests} // Elementos del carrusel
      enableReadMore={true}
      detailUrl={"church-request"}
      className="church-request"
    />
  );
};

export default ChurchRequests;
