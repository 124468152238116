// src/components/Donation.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { BankOutlined, CreditCardOutlined, UserOutlined } from '@ant-design/icons';
import './Donation.css';

const Donation = () => {
  const { t } = useTranslation();

  // Información de donación declarada dentro del componente
  const bankName = 'BBVA';
  const bankAccount = 'ES0901820879160201607714';
   const bankUser = ' Iglesia cristiana china en España';
   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  return (
    <div className="donation-container">
      <Card title={t('donate')} className="donation-card">
        <div className="donation-details">
          <p><BankOutlined /> {t('bankName')}: {bankName}</p>
          <p>{!isMobile && <CreditCardOutlined />} {t('bankAccount')}: {bankAccount}</p>
         <p><UserOutlined /> {t('bankUser')}: {bankUser}</p>
       </div>
      </Card>
    </div>
  );
};

export default Donation;
