import React from 'react';

// Componente para renderizar contenido basado en el formato JSON
const RichTextRenderer = ({ content }) => {
  const renderStyledText = (child, index) => {
    let styledText = child.text;

    if (child.type === 'link') {
      return (
        <a
          key={`${child.url}-${index}`}
          href={child.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {child.children.map((linkChild, linkIndex) =>
            renderStyledText(linkChild, linkIndex)
          )}
        </a>
      );
    }

    // Aplica los estilos en orden: negrita, cursiva, y subrayado
    if (child.bold) {
      styledText = <strong key={`bold-${index}`}>{styledText}</strong>;
    }
    if (child.italic) {
      styledText = <em key={`italic-${index}`}>{styledText}</em>;
    }
    if (child.underline) {
      styledText = <u key={`underline-${index}`}>{styledText}</u>;
    }

    return styledText;
  };

  const renderChildren = (children, indentLevel = 0) => {
    return children.map((child, index) => {
      if (child.type === 'list') {
        const ListTag = child.format === 'ordered' ? 'ol' : 'ul';
        return (
          <ListTag
            key={`list-${child.format}-${indentLevel}-${index}`}
          >
            {renderChildren(child.children, indentLevel + 1)}
          </ListTag>
        );
      } else if (child.type === 'list-item') {
        // Renderiza un ítem de lista
        return (
          <li
            key={`list-item-${indentLevel}-${index}`}
          >
            {renderChildren(child.children, indentLevel)}
          </li>
        );
      } else if (child.type === 'text' || child.type === 'link') {
        // Renderiza texto o enlaces
        return renderStyledText(child, index);
      }
      return null;
    });
  };

  return (
    <>
      {content?.map((block, index) => {
        const blockKey = `${block.type}-${index}`;

        switch (block.type) {
          case 'list':
            const ListTag = block.format === 'ordered' ? 'ol' : 'ul';
            return (
              <ListTag key={blockKey}>
                {renderChildren(block.children || [], 0)}
              </ListTag>
            );

          case 'heading':
            const HeadingTag = `h${block.level}`;
            return (
              <HeadingTag key={blockKey}>
                {block.children.map((child, childIndex) =>
                  renderStyledText(child, childIndex)
                )}
              </HeadingTag>
            );

          case 'quote':
            return (
              <blockquote key={blockKey}>
                {block.children.map((child, childIndex) =>
                  renderStyledText(child, childIndex)
                )}
              </blockquote>
            );

          case 'paragraph':
            return (
              <p key={blockKey}>
                {block.children.map((child, childIndex) =>
                  renderStyledText(child, childIndex)
                )}
              </p>
            );
          case 'image':
            return (
              <div key={blockKey} className="image-container">
                <img
                  className='rich-text-img'
                  src={block.image.url}
                  alt={block.image.alternativeText || block.image.name}
                />
              </div>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

export default RichTextRenderer;
